import { securedWrap } from '@mop/shared/utils/securedWrap';
import { createCanonicalTag } from '@mop/shared/utils/util';
import { getImage } from '@mop/cms/utils/utils';
import type { LocaleObject } from '@/types/locale';
import { COUNTRY_MAP, defaultLocale } from '@/i18n/localeList';
import type { Alternate, CmsStoryModel, SeoBreadcrumb } from '@/types/cms';
import type { SeoHeadAttributes, ArticleSchema } from '@/types/seo';
import type { JobModel } from '@/types/jobModel';

type SeoComposableStorage = {
  alternates: Alternate[];
};

function createDescriptionTag(description: string) {
  if (!description) {
    return;
  }
  return {
    name: 'description',
    content: description,
  };
}

function createRobotsTag(robots: string) {
  if (!robots) {
    return;
  }
  return {
    name: 'robots',
    content: robots,
  };
}

function getFullUrl(baseUrl: string, url: string): string {
  if (!url) {
    return '';
  }
  if (url.startsWith('/')) {
    url = url.replace(/^\//, '');
  }

  if (!url.startsWith('http')) {
    url = `${baseUrl}/${url}`;
  }

  return decodeURIComponent(url);
}

function createAlternates(baseUrl: string, alternates: Alternate[]) {
  if (!alternates || alternates.length === 0) {
    return [];
  }

  const hrefLangs = alternates.flatMap((alternate: Alternate) => {
    const lang: string = alternate.lang;
    return {
      hid: lang,
      rel: 'alternate',
      hreflang: lang,
      href: `${baseUrl}/${alternate.lang}${alternate.href}`,
    };
  });

  const defaultAlternate: Alternate | undefined = alternates.find((alternate) => alternate.lang === defaultLocale);
  if (defaultAlternate) {
    hrefLangs.push({
      hid: 'x-default',
      rel: 'alternate',
      hreflang: 'x-default',
      href: `${baseUrl}/${defaultAlternate.lang}${defaultAlternate.href}`,
    });
  }

  return hrefLangs;
}

export default function useMopSeo() {
  const storage = initStorage<SeoComposableStorage>('useSeo');
  const { $mopI18n, $config, $storyblokLivePreview } = useNuxtApp();
  const route = useRoute();
  const config = useRuntimeConfig();

  function getAlternates(): Alternate[] {
    return storage.get('alternates') || ([] as Alternate[]);
  }

  function setAlternates(alternates: Alternate[]) {
    storage.saveAndGet('alternates', alternates);
  }

  function getHeadObject(seoHeadParameters: SeoHeadAttributes) {
    const { title, description, isIndexable = true, canonical = '', script } = seoHeadParameters;
    let { alternates = [] } = seoHeadParameters;

    const fullUrl: string = getFullUrl(config.public.BASE_URL, canonical);
    const ogTitle: string = seoHeadParameters.ogTitle || title;
    const ogDescription: string = seoHeadParameters.ogDescription || description;
    const ogimage: string = (seoHeadParameters?.ogImage as any)?.filename || `${config.public.BASE_URL}/logo-og.png`;
    const descriptionObject = createDescriptionTag(description);
    let metaRobots = constants.META.NOT_INDEXABLE;
    let metaCanonicalObject;
    let metaAlternates: any[] = [];
    const localeList = $mopI18n.localeList;
    if (isIndexable && config.public.IS_INDEXBLE) {
      // only pages that are indexable, should have a canonical and alternates href langs.
      metaRobots = constants.META.INDEXABLE;
      metaCanonicalObject = createCanonicalTag(config.public.BASE_URL, canonical);

      if (alternates.length === 0) {
        const canonicalWithoutLocale = canonical.substring(6);
        alternates = $mopI18n.localeList.map((locale: LocaleObject) => ({
          href: canonicalWithoutLocale,
          lang: locale?.code ?? '',
        }));
      }
      if (alternates.length > 0 && isProductionBuild && !$storyblokLivePreview.isEnabled) {
        alternates = alternates!.filter((alternate: any) => {
          const locale = localeList.find((locale) => locale.code === alternate.lang);
          return !locale?.isDisabled;
        });
      }

      const isCanonicalPartOfAlternates =
        alternates?.some((alternate: any) => `/${alternate.lang}${alternate.href}` === canonical) ?? false;
      const isCanonicalUrlBrowserUrl = canonical === route.fullPath;

      if (isCanonicalPartOfAlternates && isCanonicalUrlBrowserUrl) {
        metaAlternates = createAlternates(config.public.BASE_URL, alternates);
      }
    }

    const robotsObject = createRobotsTag(metaRobots);
    const ogObjects: any[] = [
      {
        hid: 'og:title',
        property: 'og:title',
        content: ogTitle,
      },
      {
        hid: 'og:type',
        property: 'og:type',
        content: 'Website',
      },
      {
        hid: 'og:url',
        property: 'og:url',
        content: fullUrl,
      },
      {
        hid: 'og:description',
        property: 'og:description',
        content: ogDescription,
      },
      {
        hid: 'og:image',
        property: 'og:image',
        content: ogimage,
      },
      {
        hid: 'twitter:card',
        property: 'twitter:card',
        content: 'summary_large_image',
      },
      {
        hid: 'twitter:site',
        property: 'twitter:site',
        content: '@marcopolo',
      },
      {
        hid: 'twitter:image',
        property: 'twitter:image',
        content: ogimage,
      },
    ];

    return {
      title,
      meta: [{ ...descriptionObject }, { ...robotsObject }, ...ogObjects],
      link: [{ ...metaCanonicalObject }, ...metaAlternates],
      script,
    };
  }

  function getAlternatesFromStory(cmsStoryModel: CmsStoryModel) {
    const allowedLangs: string[] = $mopI18n.localeList.map((locale) => locale.lang);
    const alternates: Alternate[] = cmsStoryModel
      ?.getAlternates(false)
      .filter((alternate: Alternate) => allowedLangs.includes(alternate.lang));
    setAlternates(alternates);
    return alternates;
  }

  function handleHeadForCmsPage(cmsStoryModel: CmsStoryModel) {
    const seoData = cmsStoryModel?.getSeo();
    const canonical = cmsStoryModel?.getCanonical() || route.path;
    const alternates: Alternate[] = getAlternatesFromStory(cmsStoryModel);
    setAlternates(alternates);
    const storyName = cmsStoryModel?.getName() || '';
    const title = seoData?.title || storyName || `${$mopI18n.t('locale.meta.title')}`;
    const description = seoData?.description || `${$mopI18n.t('locale.meta.description')} - ${storyName}`;
    const isIndexable = seoData?.isIndexable !== false;
    const ogImage: string = cmsStoryModel?.getAttribute('ogImage');
    const script = [];

    if (cmsStoryModel.isNewsArticle() || cmsStoryModel.isBlogPost()) {
      const articleSchema: ArticleSchema = {
        '@context': 'https://schema.org',
        '@type': cmsStoryModel.isNewsArticle() ? 'NewsArticle' : 'BlogPosting',
        headline: cmsStoryModel.getAttribute('title'),
        articleSection: cmsStoryModel.getAttribute('category'),
        description: cmsStoryModel.getAttribute('subTitle'),
        image: getImage(cmsStoryModel.getAttribute('previewImage'))?.url,
        datePublished: cmsStoryModel.getAttribute('publishedDate'),
      };
      script.push({
        type: 'application/ld+json',
        children: JSON.stringify(articleSchema),
      });
    }

    return getHeadObject({
      title,
      description,
      isIndexable,
      canonical,
      alternates,
      ogImage,
      script,
    });
  }

  function getBreadcrumbSchema(breadcrumbs: SeoBreadcrumb[]) {
    return {
      type: 'application/ld+json',
      children: {
        '@context': 'https://schema.org',
        '@type': 'BreadcrumbList',
        itemListElement: breadcrumbs.map((breadcrumb, i) => {
          return {
            '@type': 'ListItem',
            position: ++i,
            name: breadcrumb.name,
            item: `${config.public.BASE_URL}${$mopI18n.localePath(breadcrumb.url)}`,
          };
        }),
      },
    };
  }

  function getJobSchema(job: JobModel) {
    const ogCountry: string = COUNTRY_MAP[job.getCountryId()];
    const datePosted: any = job.getPublishedDate() ? new Date(job.getPublishedDate()).toISOString() : '';

    return {
      type: 'application/ld+json',
      children: {
        '@context': 'https://schema.org',
        '@type': 'JobPosting',
        title: job.getTitle(),
        description: job.getDescription1(),
        datePosted,
        directApply: true,
        hiringOrganization: {
          '@type': 'Organization',
          sameAs: 'Marc O’Polo',
          name: 'Marc O’Polo',
        },
        employmentType: job.getSeoScope(),
        jobLocation: {
          '@type': 'Place',
          address: {
            addressLocality: job.getLocation(),
            addressRegion: job.getRegion(),
            addressCountry: ogCountry,
            postalCode: job.getZipCode(),
            streetAddress: job.getFullAddress(),
          },
        },
      },
    };
  }

  function getHomepageSchemas() {
    const url = `${config.public.BASE_URL}/${$mopI18n.locale}`;
    return [
      {
        type: 'application/ld+json',
        children: {
          '@context': 'https://schema.org',
          '@type': 'Organization',
          url: $config.BASE_URL,
          logo: `${$config.BASE_URL}/logo-og.png`,
        },
      },
      {
        type: 'application/ld+json',
        children: {
          '@context': 'https://schema.org',
          '@type': 'WebSite',
          url,
          potentialAction: {
            '@type': 'SearchAction',
            target: {
              '@type': 'EntryPoint',
              urlTemplate: url + '/search?q={search_term_string}',
            },
            'query-input': 'required name=search_term_string',
          },
        },
      },
    ];
  }

  return securedWrap({
    getAlternates,
    setAlternates,
    getHeadObject,
    handleHeadForCmsPage,
    getBreadcrumbSchema,
    getHomepageSchemas,
    getJobSchema,
    getAlternatesFromStory,
  });
}
